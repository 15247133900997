<template>
  <div>
    <!-- begin::Sticky Toolbar -->
    <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <li v-b-tooltip.hover.left="`${$t('COMMON.SAVE')}`" class="nav-item mb-2">
        <a
          @click="save"
          class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
        >

          <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/disk.svg"></inline-svg>
              <!--end::Svg Icon-->
          </span>

        </a>
      </li>
      <li v-b-tooltip.hover.left="`${$t('COMMON.COPY_LINK')}`" class="nav-item mb-2">
        <a
          @click="copyLinkClicked"
          class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
        >

        <img style="max-width: 32px" src="@/assets/images/link.png" />

        </a>
      </li>

    </ul>
    <!-- end::Sticky Toolbar -->
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: "KTStickyToolbar",
  props: ['member_id'],
  emits: ['saveClicked'],
  mixins: [ toasts ],
  methods: {
    save() {
      this.$emit('saveClicked');
    },
    copyLinkClicked() {
      this.copyLink(this.member_id);
    },
    copyLink(member_id) {
      navigator.clipboard
        .writeText(
          `${window.location.origin}/ml-member-editor/${member_id}`
        )
        .then(() => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SHOP_ORDER.CLIPBOARD_COPY'));
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_CLIPBOARD_COPY'));
        });
    },

  }
};
</script>
