<template>
  <b-modal size="lg" ref="notification-popup"  no-close-on-backdrop hide-footer @hide="on_hide">


    <div class="d-flex flex-column">
      <div class="w-100 h-100 align-items-center text-center flex-wrap justify-content-center">
        <i v-if="new_version || internet_error" class="fa fa-exclamation-triangle" style="font-size: 42px; color: #eca350;"></i>
        <i v-else-if="notification" class="fa fa-question-circle" style="font-size: 42px; color: #085f88;"></i>
      </div>

      
      <hr />

      <div v-if="internet_error">
        <div>
          
          <div class="w-100 align-items-center text-center justify-content-center">

            <h4 class="header-name mt-8" style="text-align: center; flex: 1;">{{ $t('USER.INTERNET_ERROR') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.INTERNET_ERROR_INFO') }}</p>

            <p class="mt-8"><b-button variant="success" @click.prevent="reloadApp()">{{$t('USER.UPDATE_PAGE')}}</b-button></p>

          </div>
        </div>
      </div>

      
      <div v-else-if="new_version">
        <div>
          
          <div class="w-100 align-items-center text-center justify-content-center">

            <h4 class="header-name mt-8" style="text-align: center; flex: 1;">{{ $t('USER.NEW_VERSION_HEADER') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.NEW_VERSION_INFO') }}</p>

            <p class="mt-8"><b-button variant="success" @click.prevent="reloadApp()">{{$t('USER.UPDATE_PAGE')}}</b-button></p>

          </div>
        </div>
      </div>

      <div v-else-if="notification">

        <div v-if="notification.type === 'FEEDBACK_FORM'">
          <div class="h-100 align-items-center text-center flex-wrap justify-content-center">

            <h4 class="header-name mt-8" style="text-align: center;">{{ $t('USER.FEEDBACK_HEADER') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.PLEASE_PROVIDE_FEEDBACK') }}</p>

            <p class="mt-8"><b-button variant="success" @click.prevent="clicked_goto_url()">{{$t('USER.GOTO_FEEDBACK')}}</b-button></p>
            
          </div>
        </div>

        <div v-if="notification.type === 'PATCH_NOTES'">
          <div class="h-100 align-items-center text-center flex-wrap justify-content-center">
            <h4 class="header-name mt-8" style="text-align: center; min-width: 512px;">{{ $t('USER.NEW_PATCH_NOTES') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.PLEASE_READ_PATCH_NOTES') }}</p>
            
            <p class="mt-8"><b-button variant="success" @click.prevent="clicked_goto_url()">{{$t('USER.GOTO_RELEASE_NOTES')}}</b-button></p>
          </div>
        </div>
        
      </div>

      <div v-else-if="!currentUser">

        <div>
          <div class="h-100 align-items-center text-center flex-wrap justify-content-center">
            <h4 class="header-name mt-8" style="text-align: center; min-width: 512px;">{{ $t('USER.NOT_LOGGED_IN') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.YOU_ARE_NOT_LOGGED_IN') }}</p>
            
            <p class="mt-8"><b-button variant="success" @click.prevent="clicked_goto_login()">{{$t('USER.GOTO_LOGIN')}}</b-button></p>
          </div>
        </div>

      </div>

    </div>
  </b-modal>
</template>
  
<style lang="scss" scoped></style>
  
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';

const IS_DEV = process.env.NODE_ENV === 'development';

export default {
  name: 'NotificationPopup',
  
  data() {
    return {
      notification: null,

      current_hash: '{{ HASH }}', /// this is replaced by vue.config.js in ReplaceHashInFileWebpackPlugin
      new_version: false,

      internet_error: false,

      check_version_interval_id: null
    };
  },
  props: {
    
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentCompanyId',
      'currentPeriodId'
    ]),
  },
  async mounted() {
    
    if (!IS_DEV) {
      this.init_version_check(1000 * 10);  
    }
    
    this.show_if_notification();
    
  },
  watch: {
    
  },
  methods: {

    clicked_goto_login() {
      window.location.reload();
    },

    on_hide() {
      if (this.new_version) {
        this.reloadApp();
      }
    },

    init_version_check(frequency = 1000 * 60 * 2) {

      /// dont start check if there is a new version already
      if (this.new_version) {
        return;
      }


      this.check_version_interval_id = setInterval(async () => {
        this.new_version = await this.check_version();
        if (this.new_version) {
          this.show();
        }
      }, frequency);
    },
    async check_version() {
      try {
        const res = await axios.get(`/version`);

        if (res.status !== 200) {
          return false;
        }

        if (this.current_hash !== res.data.hash) {
          return true;
        }
        
      } catch (error) {
        console.error('check version error', error);

        this.internet_error = true;
        this.show();

        if (this.check_version_interval_id) {
          clearInterval(this.check_version_interval_id);
        }
        
      }

      return false;
    },

    reloadApp() {
      window.location.reload();
    },


    async clicked_goto_url() {
      try {
        const ok = await this.post_clicked_notification();

        if (ok) {
          console.log('OK - Will not get notification any more');
        }
        else {
          console.error('Got non ok status for clicked...');
        }

        window.open(this.notification.url, '_blank')
      }
      catch (err) {
        console.error('clicked_goto_url error', err);
      }
    },

    async show_if_notification() {
      try {

        if (!IS_DEV) {
          // show version upgrade first
          const new_version = await this.check_version();

          if (new_version) {
            clearInterval(this.check_version_interval_id);
            this.new_version = true;
            this.show();
            return;
          }
        }
  
        const notif = await this.get_pending_notification();

        if (notif !== null && notif.notification) {
          this.notification = notif.notification;

          this.show();
        }
      }
      catch (err) {
        console.error('show_if_notification error', err);
      }
    },

    show() {
      this.$refs['notification-popup'].show();
    },
    onOk() {
      this.$refs['notification-popup'].hide();
    },

    async post_clicked_notification() {
      try {
        const res = axios.post(`/notification/clicked`, {
          notification_id: this.notification.id
        });

        if (res.status === 201) {
          return true;
        }
      }
      catch (err) {
        console.error('post_clicked_notification url', err);
      }

      return false;
    },

    async get_pending_notification() {
      try {
        const res = await axios.get(`/notification/pending`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_pending_notification error', err);
      }

      return null;
    }
  }
};
</script>
  