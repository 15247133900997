<template>
  <b-modal size="lg" ref="support-popup" no-close-on-backdrop hide-footer @hide="on_hide">


    <div class="d-flex flex-column">
      <div class="w-100 h-100 align-items-center text-center flex-wrap justify-content-center">
        <i class="fa fa-question-circle" style="font-size: 42px; color: #085f88;"></i>
      </div>


      <hr />

      <div>
        <div>

          <div class="w-100 align-items-center text-center justify-content-center">

            <h4 class="header-name mt-8" style="text-align: center; flex: 1;">{{ $t('USER.SUPPORT_NEEDED_HEADER') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.SUPPORT_NEEDED_INFO') }}</p>

            <p class="mt-8"><a @click.prevent="click_documentation" href="https://memlist.se/docs">https://memlist.se/docs</a></p>

            <p class="mt-8"><a @click.prevent="click_support_email" href="mailto:support@memlist.se">{{ $t('USER.SUPPORT_NOT_IN_DOCUMENTATION') }}</a></p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
    
<style lang="scss" scoped></style>
    
<script>

import axios from 'axios';

export default {
  name: 'SupportPopup',

  data() {
    return {

    };
  },
  props: {

  },
  async mounted() {

  },
  watch: {

  },
  methods: {

    click_documentation() {
      window.open('https://memlist.se/docs', '_blank')
    },

    click_support_email() {
      window.open('mailto:support@memlist.se', '_blank')
    },

    on_hide() {

    },


    show() {
      this.$refs['support-popup'].show();
    },
    onOk() {
      this.$refs['support-popup'].hide();
    },

    
  },
  computed: {

  }
};
</script>
    