<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader> -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>

    <b-modal v-if="!isAuthenticated" show visible hide-footer hide-header no-close-on-backdrop>
      <h6>{{ $t('USER.SESSION_ENDED') }}</h6>
      <p class="mt-8"><a :href="ulogin_link">{{ $t('USER.CLICK_TO_LOGIN_AGAIN') }} >></a></p>
    </b-modal>

    <NotificationPopup />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTAside from '@/view/layout/aside/Aside.vue';
import KTHeader from '@/view/layout/header/Header.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTSubheader from '@/view/layout/subheader/Subheader.vue';
import KTStickyToolbar from '@/view/layout/extras/StickyToolbar.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop.vue';
import dayjs from 'dayjs';
import Loader from '@/view/content/Loader.vue';
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from '@/core/services/store/htmlclass.module.js';

import { initUserMemlist } from '@/core/services/initUserMemlist.js';
import store from '@/core/services/store';
import { parseJwt } from '@/core/services/jwt.service';
import NotificationPopup from '@/view/components/NotificationPopup.vue';

export default {

  data() {
    return {
      show: true,
      interval_id: null
    };
  },
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    NotificationPopup
},
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {

    this.check_refresh_token_expired();

    initUserMemlist((auth, init) => {
      console.error('Layout.vue: initUserMemlist', auth, init);
    });

    this.interval_id = setInterval(this.check_refresh_token_expired, 1000 * 60);

  },
  watch: {

    isAuthenticated(newValue) {
      console.error('Layout.vue: detected authentication state change', newValue);
    }
  },
  methods: {

    check_refresh_token_expired() {
      
      try {
        if (!this.user_refresh_token || this.user_refresh_token.length < 16) {
          console.error('check_refresh_token_expired invalid user refresh token view/Layout', this.user_refresh_token);
          return;
        }

        const parsed = parseJwt(this.user_refresh_token);

        const cd = dayjs.unix(parsed.exp);
        const now = dayjs().add(1, 'minute');

        console.log('refresh token will expire at ', cd.format('YYYY-MM-DD HH:mm:ss'), 'now is', now.format('YYYY-MM-DD HH:mm:ss'));

        if (cd < now) {
          console.log('>> check_refresh_token_expired, User refresh token has expired', cd, now, this.user_refresh_token)

          clearInterval(this.interval_id);

          console.log('>> redirecting');

        }
      }
      catch (err) {
        clearInterval(this.interval_id);
        
        console.error('token has expired with error', err);
        console.log('>> Token expired exception, purging auth');
        
      }

    },

    closeModal() {
      this.show = false;
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig','ulogin_link', 'user_refresh_token']),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    }
  }
};
</script>

<style lang="scss" scoped>

:deep .v-application--wrap {
  min-height: 0px !important;
}
.refresh-container {
  width: 600px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: calc(50% - 200px);
  right: calc(50% - 300px);
  padding: 25px;
  z-index: 9999;
  font-size: 24px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}
.close-refresh-modal {
  position: absolute;
  right: 15px;
  top: 5px;
  border: none;
}
</style>
