<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >

      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2" v-html="copyright">
          
        </span>
        <a
          href="#"
          target="_blank"
          class="text-dark-75 text-hover-primary line-link gray-link"
        >
          ThinkingHat Studios AB {{ version }}
        </a>
      </div>
      <div class="nav nav-dark">
        
        <a
          @click.prevent="open_support_popup"
          class="nav-link pl-3 pr-0"
        >
          Support
        </a>
      </div>

      <SupportPopup 
        ref="support-popup"
      />
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<style lang="css" scoped>
a:hover {
  cursor: pointer;
}
</style>

<script>
import { mapGetters } from "vuex";
import dayjs from 'dayjs';

import SupportPopup from '@/view/components/SupportPopup.vue';


export default {
  name: "KTFooter",
  components: {
    SupportPopup
  },
  methods: {
    open_support_popup() {
      this.$refs['support-popup'].show();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig",'version']),

    copyright() {
      return `© ${dayjs().format('YYYY')}`;
    },

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
