<template>
 <b-modal ref="notes_modal" size="lg" v-on:shown="load_notes" centered hide-footer no-enforce-focus no-close-on-backdrop :title="$t('COMPANY.THS_NOTES')">
    <tiptap-vuetify
      data-app
      v-model="notes"
      :extensions="extensions"
    />
    
    <b-row class="mt-4">
      <b-col lg="12">
        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="primary" v-on:click="save_notes">{{$t('COMMON.SAVE')}}</b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

export default {
  name: 'company-notes',
  mixins: [ toasts ],
  components: {
    TiptapVuetify,
  },
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      notes: '',
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  mounted() {
    
  },
  methods: {
    load_notes() {
      axios 
        .get(`/company/${this.currentCompanyId}`)
        .then(res => {
          this.notes = res.data.notes;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_GET_NOTES'));
        });
    },
    save_notes() {
      axios 
        .put(`/company/${this.currentCompanyId}`, { notes: this.notes })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY.UPDATED_NOTES'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_UPDATE_NOTES'));
        });

    },
    show() {
      this.$refs['notes_modal'].show();
    }
  }
};
</script>