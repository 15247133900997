<template>
  <ul class="menu-nav">
    <router-link to="/ml-dashboard" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-dashboard')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-start-up"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.DASHBOARD') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-todo" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-todo')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.TODO') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-errors" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-errors')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-warning-sign"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.ERROR') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-events" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-events')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-presentation"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.EVENT') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-reports" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-reports')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-file"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.REPORTS') }}</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="section('members')">
      <h4 class="menu-text">{{ $t('AUTH.MENU.MEMBERS') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link to="/ml-members-control" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-members-control')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-signs-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.MEMBERS_CONTROL') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-advanced-search" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-advanced-search')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-search"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.ADV_SEARCH') }}</span>
        </a>
      </li>
    </router-link>


    <router-link to="/ml-duplicates" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-duplicates')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-warning"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.DUPLICATES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-spar" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-spar')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-clipboard"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SPAR') }}</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link to="/ml-advanced-search-test" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-advanced-search')">
      <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-signs-1"></i>
          <span class="menu-text">Ny avancerad sök</span>
        </a>
      </li>
    </router-link> -->

    <router-link to="/ml-import" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-import')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-file-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.IMPORT') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-stickprov" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-stickprov')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-phone"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.STICKPROV') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-ladok" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-ladok')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-box-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.LADOK') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-properties" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-properties')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-box-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.ATTRIBUTES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-renew-page" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-renew-page')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-box-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.RENEWS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-member-registrations" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-member-registrations')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-map"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.MEMBER_REGISTRATIONS') }}</span>
        </a>
      </li>
    </router-link>

    
    <router-link to="/ml-payments" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-payments')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-box-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.ORDERS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-invoices" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-invoices')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-contract"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.INVOICES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-fortnox-invoices" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-fortnox-invoices')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-contract"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.FORTNOX_INVOICES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-redeem-codes" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-redeem-codes')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-gift"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.COUPONS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-jobs" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-jobs')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-contract"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.JOBS') }}</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="section('association')">
      <h4 class="menu-text">FÖRENING</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link to="/ml-grant" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-grant')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.GRANT') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-mucf" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-mucf')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list-3"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.MUCF') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-settings" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-settings')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-gear"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SETTINGS') }}</span>
        </a>
      </li>
    </router-link>


    <router-link to="/ml-forms" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-forms')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-app"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.FORMS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-registersetup" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-registersetup')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-app"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.REGISTERSETUP') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-users" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-users')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-safe"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.USERS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-subcompany" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-subcompany')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-map"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SUBCOMPANY') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-faith" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-faith')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-map"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.FAITH') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-importcompanies" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-importcompanies')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-map"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.IMPORTCOMPANIES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-files" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-files')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-folder-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.FILES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-shopitem" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-shopitem')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-price-tag"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SHOPITEM') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-book" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-book')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-sheet"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.BOOK') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-transactions" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-transactions')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-tag"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.TRANSACTIONS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-education" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-education')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-box-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.EDUCATION') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-swish-merchant" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-swish-merchant')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SWISH_MERCHANT') }}</span>
        </a>
      </li>
    </router-link>


    <li class="menu-section" v-if="section('communication')">
      <h4 class="menu-text">{{ $t('AUTH.MENU.COMMUNICATION') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link to="/ml-sendout" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-sendout')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-mail-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SENDOUT') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-sms" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-sms')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-mail-1"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SMS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-templates" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-templates')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-11"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.TEMPLATES') }}</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="section('admin')">
      <h4 class="menu-text">Administration</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link to="/ml-billing" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-billing')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-9"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.BILLING') }}</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="isTHS">
      <h4 class="menu-text">Super Admin</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li v-if="isTHS" aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <CompanyNotes ref="notes_modal"/> <!-- this dialog is triggered by this menu item -->
      <a class="menu-link" @click.prevent="show_notes">
          <i class="menu-icon flaticon2-sheet"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.NOTES') }}</span>
      </a>
    </li>

    <router-link to="/ml-companies" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-companies')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-open-box"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.COMPANIES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-periods" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-periods')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-clock-2"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.PERIODS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-history" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-history')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-cogwheel"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.HISTORY') }}</span>
        </a>
      </li>
    </router-link>


    <router-link to="/ml-system" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-system')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-cogwheel"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SYSTEM') }}</span>
        </a>
      </li>
    </router-link>


    <router-link to="/ml-redoc" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-redoc')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-book"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.REDOC') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-apikeys" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-apikeys')" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon">
            <inline-svg src="/assets/svg/key.svg" style="width: 17px; height: 17px;"></inline-svg>
          </span>
          <span class="menu-text">{{ $t('AUTH.MENU.APIKEYS') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-page" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-page')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-grids"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.PAGE') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-ths-logs" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-ths-logs')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.THS_LOGS') }}</span>
        </a>
      </li>
    </router-link>


    <router-link to="/ml-ths-instances" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-ths-instances')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.THS_INSTANCES') }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-support-admin" v-slot="{ href, navigate, isActive, isExactActive }" v-if="hasAccess('ml-ths-instances')">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">{{ $t('AUTH.MENU.SUPPORT_ADMIN') }}</span>
        </a>
      </li>
    </router-link>


  </ul>
</template>
<style lang="scss" scoped>
li:hover > a > span > svg,
.menu-item-active > a > span > svg {
  fill: #3699FF;
}
</style>

<script>

import CompanyNotes from '@/view/components/CompanyNotes';
import { mapGetters } from 'vuex';
import { hasAccessToPageForCurrentCompany } from '@/core/services/companyAccess';

export default {
  name: 'KTMenu',
  components: {
    CompanyNotes
  },
  computed: {
    ...mapGetters(['currentAccess','currentCompanyId', 'isTHS'])
  },
  data() {
    return {
      access: {}
    }
  },
  mounted() {
  },
  watch: {
    currentAccess(newValue, oldVal) {
    },
  },
  methods: {
    show_notes() {
      this.$refs['notes_modal'].show();
    },
    hasAccess(page) {
      return hasAccessToPageForCurrentCompany(page);
    },
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    section(name){
      let list = [];
      switch (name) {
        case 'members':
          list = ['ml-member-editor', 'ml-members-control', 'ml-advanced-search', 'ml-import', 'ml-stickprov', 'ml-ladok', 'ml-properties', 'ml-renew-page', 'ml-payments'];
          break;
        case 'association':
          list = ['ml-mucf', 'ml-settings', 'ml-registersetup', 'ml-periods', 'ml-users', 'ml-subcompany', 'ml-files', 'ml-shopitem', 'ml-book', 'ml-importcompanies', 'ml-transactions'];
          break;
        case 'communication':
          list = ['ml-sendout', 'ml-templates'];
          break;
        case 'admin':
          list = ['ml-billing'];
          break;
        default :
          break;
      }

      for (let i = 0; i < list.length; i++){
        if (this.hasAccess(list[i]))
          return true
      }
      return false;
    },
  }
};
</script>
